import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'; // Importe BrowserRouter, Routes, Route e Navigate
import './App.css';
import LoginScreen from './login'; // Supondo que seu componente de tela de login esteja em './login'
import SOSPage from './sos'; // Supondo que seu componente de tela SOS esteja em './sos'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginScreen />} /> {/* Rota para a tela de login */}
          <Route path="/sos" element={<SOSPage />} /> {/* Rota para a tela SOS */}
          <Route path="/sos/*" element={<Navigate to="/" />} /> {/* Redireciona todas as rotas que começam com /sos para a tela de login */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
