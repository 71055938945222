import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SOSButton = ({ onPress, isFlashing }) => (
  <button
    style={{
      backgroundColor: 'red',
      width: '50vw', // Largura relativa à largura da viewport
      height: '50vw', // Altura relativa à largura da viewport
      borderRadius: '50%', // Transforma o botão em um círculo
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: isFlashing ? '4px' : '0',
      borderColor: isFlashing ? 'lightcoral' : 'transparent',
      fontSize: '10vw', // Tamanho do texto relativo à largura da viewport
      color: '#fff', // Cor do texto
      fontWeight: 'bold', // Peso da fonte
      outline: 'none', // Remove a borda de foco ao clicar
      cursor: 'pointer', // Altera o cursor ao passar o mouse
    }}
    onClick={onPress}
  >
    SOS
  </button>
);


const chave = localStorage.getItem('chave');

const SOSPage = () => {
  const [isFlashing, setFlashing] = useState(false);
  const [locationInfo, setLocationInfo] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o usuário está logado
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isLoggedIn) {
      // Se não estiver logado, redireciona para a página de login
      navigate('/sos');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude.toString();
            const longitude = position.coords.longitude.toString();
            setLocationInfo({ latitude, longitude });
          },
          (error) => {
            console.error('Erro ao obter a localização:', error);
          },
          { enableHighAccuracy: true, timeout: 30000, maximumAge: 1000 }
        );
      } catch (error) {
        console.error('Erro ao obter a localização:', error);
      }
    };
    fetchLocation();
  }, []);

  const handleSOSPress = async () => {
    try {
      // Inicia o efeito de piscar
      setFlashing(true);

       // Verifica se a chave de acesso está disponível no armazenamento local
       const chave = localStorage.getItem('chave');
       if (!chave) {
         console.error('Chave de acesso não encontrada.');
         return;
       }
  
      // Verifica se a localização está disponível
      if (locationInfo) {
        const { latitude, longitude } = locationInfo;
  
        // Faz a solicitação à API de Geocodificação do Google Maps
        const geocodeResponse = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBz1pXZBEHYOXXiDlIVd75eHxjk74b0zz8`
        );
  
        // Verifica se a solicitação foi bem-sucedida
        if (geocodeResponse.ok) {
          const data = await geocodeResponse.json();
          const endereco = data.results[0].formatted_address;
  
          // Prepara os dados da solicitação
          const requestData = {
            Latitude: latitude,
            Longitude: longitude,
            Endereco: endereco
          };
  
          console.log('Dados da solicitação:', requestData);
  
          // Envia a solicitação para a API
          const apiResponse = await fetch(
            `https://buton.basezero.srv.br/webservices/cadOcorrencia.php?Latitude=${latitude}&Longitude=${longitude}&Endereco=${endereco}&Chave=${chave}`,
            {
              method: 'GET',
            }
          );
  
          // Verifica se a solicitação foi bem-sucedida
          if (apiResponse.ok) {
            // Exibe o modal de sucesso
            setShowSuccessModal(true);
            setTimeout(() => {
              setShowSuccessModal(false);
            }, 2000);
          } else {
            // Exibe um erro se a solicitação não for bem-sucedida
            console.error('Falha ao enviar a solicitação SOS. Código de status:', apiResponse.status);
            const errorText = await apiResponse.text();
            console.error('Resposta de erro:', errorText);
          }
        } else {
          console.error('Falha ao obter o endereço. Código de status:', geocodeResponse.status);
        }
      } else {
        console.warn('Localização não disponível.');
      }
    } catch (error) {
      console.error('Erro ao fazer a solicitação SOS:', error);
    } finally {
      // Para o efeito de piscar após o envio da solicitação SOS
      setFlashing(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <h2 style={{ marginBottom: '20px' }}>Precisa de ajuda?</h2>
      <p style={{ marginBottom: '20px', textAlign: 'center' }}>Pressione o botão abaixo e a ajuda chegará em breve.</p>
      <SOSButton onPress={handleSOSPress} isFlashing={isFlashing} />
      {showSuccessModal && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(0, 128, 0, 0.7)', padding: '20px', borderRadius: '10px' }}>
          <p style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>Ajuda está a caminho!</p>
        </div>
      )}
    </div>
  );
};

export default SOSPage;
