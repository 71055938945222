import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginScreen = () => {
  const [codigoAcesso, setCodigoAcesso] = useState('');
  const [status, setStatus] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se há uma chave de acesso salva
    const chaveSalva = localStorage.getItem('chave');
    if (chaveSalva) {
      navigate('/sos'); // Redireciona para a tela SOS se houver uma chave salva
    }
  }, [navigate]);

  const handleCodigoChange = (event) => {
    setCodigoAcesso(event.target.value);
  };

  const handleEntrarPress = async () => {
    try {
      if (!codigoAcesso.trim()) {
        setStatus("Por favor, insira o código de acesso.");
        return;
      }

      const response = await fetch('https://buton.basezero.srv.br/webservices/getUsuarios.php' + "?senha="+codigoAcesso, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });

      const result = await response.json();

      console.log("Resposta do servidor:", result);

      if (result.status === 'success') {
        setStatus("Sucesso");

        // Salva a chave de acesso no armazenamento local
        localStorage.setItem('chave', codigoAcesso);
        navigate('/sos');
      } else {
        setStatus(result.mensagem || "Falha na autenticação");
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
      setStatus("Erro");
    }
  };

  return (
    <div style={styles.loginContainer}>
      <div style={styles.loginContent}>
        <h2>Login</h2>
        <div style={styles.inputContainer}>
          <input
            type="text"
            value={codigoAcesso}
            onChange={handleCodigoChange}
            placeholder="Código de Acesso"
            style={styles.input}
          />
        </div>
        <button onClick={handleEntrarPress} style={styles.button}>Entrar</button>
        {status && <p style={styles.status}>{status}</p>}
      </div>
    </div>
  );
};

const styles = {
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  loginContent: {
    width: '90%',
    maxWidth: '400px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  inputContainer: {
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  status: {
    textAlign: 'center',
    marginTop: '10px',
    color: '#ff0000',
  },
};

export default LoginScreen;
